<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					Smart Hospital Solution
				</div>
				<div class="cen-msg">
					Through online service portal and Kiosks, to provide overall patient services covering
					<br />
					pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the
					<br />
					payment and inquiry transactions, and provide personalized patient care,
					thus<br /> improve efficiency and patient satisfaction.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="top-title">
						Integrated online and offline service
						<br />
						throughout the whole process.
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_2_1_bot">
				</div>

			</div>
		</div>
		<div class="page_3">
			<div class="page_3_1">
				<div class="page_3_1_top">
					<div class="top-title">
						Improved visit experience – online
						<br />
						appointment and self check-in
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_3_1_bot">
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut1_2.png" alt="" />
						</div>
						<div class="item-name">
							Online Appointment
						</div>
						<div class="item-content">
							Clear schedule information of departments / doctors.
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut1_3.png" alt="" />
						</div>
						<div class="item-name">
							Self check-in
						</div>
						<div class="item-content">
							Automated process with less operation, less queuing up.
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut1_4.png" alt="" />
						</div>
						<div class="item-name">
							Queuing
						</div>
						<div class="item-content">
							Get queuing status on App or broadcast screen of waiting area.
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_4">
			<div class="page_4_1">
				<div class="page_4_1_top">
					<div class="top-title">
						Online Service Portal
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_4_1_bot">
					<div class="page_4_left">
					</div>
					<div class="page_4_right">

					</div>
				</div>


			</div>
		</div>
		<div class="page_5">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						Hospital Kiosk Products
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_5_1_bot">
					<div class="page_5_left">
					</div>
					<div class="page_5_right">
						<img src="../../assets/images/soluts/solut1_8.png" alt="" />
						<img src="../../assets/images/soluts/solut1_9.png" alt="" />
					</div>
				</div>


			</div>
		</div>
		<div class="page_6">
			<div class="page_6_1">
				<div class="page_6_1_top">
					<div class="top-title">
						Outpatient Self-Services Supported on Kiosk
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_6_1_bot">
					<div class="page_6_left">
					</div>
					<div class="page_6_right">

					</div>
				</div>


			</div>
		</div>
		<div class="page_7">
			<div class="page_7_1">
				<div class="page_7_1_top">
					<div class="top-title">
						Inpatient Self-Services Supported on Kiosk
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_7_1_bot">
				</div>

			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

			screenWidth: {
				handler: function(val) {
					// console.log("当前屏幕尺寸--" + val)
					// console.log(this.list_status)
					if (val != null && val < 742) {
						this.list_status = false
					} else {
						this.list_status = true
					}
					// console.log(this.list_status)
				},
				immediate: true,
				deep: true
			},
		},

		mounted() {
			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth // 监听屏幕宽度
				})()
			}

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				acctive_img: require("../../assets/images/about/box_1.png"),
				show_top: false,
				data_list: [{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						acctive: true
					}, {
						id: 1,
						img: require("../../assets/images/about/box_2.png"),
						acctive: false
					},
					{
						id: 2,
						img: require("../../assets/images/about/box_3.png"),
						acctive: false
					},
					{
						id: 3,
						img: require("../../assets/images/about/box_4.png"),
						acctive: false
					},

				],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 鼠标移入-导航
			enter_img(item) {
				this.acctive_img = item.img;
				for (var i = 0; i < this.data_list.length; i++) {
					if (item.id == this.data_list[i].id) {
						this.data_list[i].acctive = true;
					} else {
						this.data_list[i].acctive = false;
					}
				}
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
				} else {
					this.navBarFixed = false;
				}
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 100vh;
		max-height: 950px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/soluts/solut1_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #FFFFFF;

		.cen-text {
			margin-top: 30vh;
			margin-left: 15%;
			font-size: 60px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}


		.cen-msg {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			background: #FFFFFF;
			padding-bottom: 100px;
	
			.page_2_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_2_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_2_1_bot {
				width: 100%;
				height: 560px;
				background-image: url("../../assets/images/soluts/solut1_1.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
	
	
			}
	
		}
	
		.page_3 {
			background: #FAFAFA;
			padding-bottom: 100px;
	
			.page_3_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_3_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_3_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_3_item {
					width: 400px;
					// height: 340px;
					display: flex;
					flex-direction: column;
	
					.top_img {
						width: 100%;
						height: 260px;
						display: flex;
						align-items: center;
						justify-content: center;
	
						img {
							width: 100%;
							height: 260px;
							object-fit: cover;
						}
					}
	
					.item-name {
						width: 82%;
						margin: 0 auto;
						margin-bottom: 10px;
						margin-top: 30px;
						font-size: 24px;
						color: #2F2F2F;
						text-align: center;
					}
	
					.item-content {
						text-align: center;
						font-size: 14px;
						color: #ACACAC;
					}
				}
	
	
	
			}
	
		}
	
		.page_4 {
			width: 100%;
			min-width: 1360px;
			padding-top: 150px;
			padding-bottom: 200px;
			background: #FFFFFF;
	
	
			.page_4_1 {
				width: 1360px;
				margin: 0 auto;
	
				.page_4_1_top {
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #000000;
						font-size: 48px;
					}
	
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #0081CC;
					}
	
				}
	
				.page_4_1_bot {
					width: 100%;
					margin-top: 80px;
					display: flex;
					align-items: center;
					justify-content: space-between;
	
					.page_4_left {
						width: 490px;
						height: 480px;
						background-image: url("../../assets/images/soluts/solut1_5.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
	
					}
	
					.page_4_right {
						width: 800px;
						height: 330px;
						background-image: url("../../assets/images/soluts/solut1_6.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
					}
				}
			}
		}
	
	
		.page_5 {
			width: 100%;
			min-width: 1360px;
			padding-top: 150px;
			padding-bottom: 200px;
			background: #FFFFFF;
			background-image: url("../../assets/images/soluts/solut0_18.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
	
	
			.page_5_1 {
				width: 1360px;
				margin: 0 auto;
	
	
				.page_5_1_top {
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #FFFFFF;
						font-size: 48px;
					}
	
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #FFFFFF;
					}
	
				}
	
				.page_5_1_bot {
					width: 92%;
					margin: 0 auto;
					margin-top: 80px;
					display: flex;
					align-items: center;
					justify-content: space-between;
	
	
					.page_5_left {
						width: 615px;
						height: 460px;
						background-image: url("../../assets/images/soluts/solut1_7.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						border-radius: 5px;
	
					}
	
					.page_5_right {
						width: 605px;
						height: 460px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
	
						img {
							width: 600px;
							height: 224px;
						}
					}
				}
			}
		}
	
	
		.page_6 {
			width: 100%;
			min-width: 1360px;
			padding-top: 150px;
			padding-bottom: 200px;
			background: #FFFFFF;
	
	
			.page_6_1 {
				width: 1360px;
				margin: 0 auto;
	
				.page_6_1_top {
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #000000;
						font-size: 48px;
					}
	
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #0081CC;
					}
	
				}
	
				.page_6_1_bot {
					width: 100%;
					margin-top: 80px;
					display: flex;
					align-items: center;
					justify-content: space-between;
	
					.page_6_left {
						width: 635px;
						height: 490px;
						background-image: url("../../assets/images/soluts/solut1_10.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
	
					}
	
					.page_6_right {
						width: 630px;
						height: 410px;
						background-image: url("../../assets/images/soluts/solut1_11.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
					}
				}
			}
		}
	
		.page_7 {
			background: #FAFAFA;
			padding-bottom: 100px;
	
			.page_7_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_7_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_7_1_bot {
				width: 100%;
				height: 630px;
				margin-top: 100px;
				background-image: url("../../assets/images/soluts/solut1_12.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
	
	
			}
	
		}
	
	
</style>